.required:after {
    content: "*";
    color: red;
}

.card-header--list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*Select2 ReadOnly Start*/
select[readonly] {
    background: #eee; /*Simular campo inativo - Sugestão @GabrielRodrigues*/
    pointer-events: none;
    touch-action: none;
}

select[readonly].select2-hidden-accessible + .select2-container {
    pointer-events: none;
    touch-action: none;
}

select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection {
    background: #eee;
    box-shadow: none;
}

select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection__arrow,
select[readonly].select2-hidden-accessible
    + .select2-container
    .select2-selection__clear {
    display: none;
}

/*Select2 ReadOnly End*/

.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}

/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8)
        url("http://i.stack.imgur.com/FhHRx.gif") 50% 50% no-repeat;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading .modal {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .modal {
    display: block;
}
