@import "~choices.js/public/assets/styles/choices.css";


.choices__list--multiple .choices__item {
    border-radius: 2px;
    background-color: $primary;
    box-shadow: 0 2px 5px rgb(2 158 255 / 10%);
    border: none;
}

.choices__input{
    color: var(--#{$variable-prefix}body-color);
}
